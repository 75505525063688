import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";

import GenerateImage from "./components/GenerateImage";
import PriceManagement from "./components/PriceManagement";

function App() {
  return (
    <Router>
      <div className="flex h-screen">
        {/* Left Sidebar Navigation */}
        <nav className="w-1/6 bg-gray-800 text-white p-4 h-full">
          <h1 className="text-2xl font-bold mb-6">NFT Seller Bot</h1>
          <ul>
            <li className="mb-4">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-lg font-bold text-blue-300"
                    : "text-lg hover:text-gray-300"
                }
                end
              >
                Generate Image
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/price-manage"
                className={({ isActive }) =>
                  isActive
                    ? "text-lg font-bold text-blue-300"
                    : "text-lg hover:text-gray-300"
                }
              >
                Price Management
              </NavLink>
            </li>
          </ul>
        </nav>

        {/* Main Content */}
        <div className="w-full bg-gray-100 overflow-hidden">
          <Routes>
            <Route path="/" element={<GenerateImage />} />
            <Route path="/price-manage" element={<PriceManagement />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
