import React, { useState, useEffect } from "react";
import classNames from "classnames";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import TodoItem from "./TodoItem";
import GenerateImageForm from "./GenerateImageForm";
import { useGetProcessingTodos } from "../hooks/useGetTodoData";
import useToast from "../hooks/useToast";
import ConfirmDialog from "./ConfirmDialog";
import { COMPLETED, PENDING } from "../config/constants";

function GenerateImage() {
  const processingTodos = useGetProcessingTodos();

  const [todos, setTodos] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [statusFilter, setStatusFilter] = useState("All");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedDelRow, setSelectedDelRow] = useState(null);
  const { showToast } = useToast();

  const filterOptions = ["All", "Pending", "Processing", "Completed", "Paused"];

  let countData = {
    All: "",
    Pending: "",
    Processing: "",
    Completed: "",
    Paused: "",
  };
  if (processingTodos?.isFetched && processingTodos?.data?.data?.data) {
    const data = processingTodos?.data?.data?.data;
    countData.All = data.length;
    countData.Pending = data.filter((e) => e.status === PENDING).length;
    countData.Completed = data.filter((e) => e.status === COMPLETED).length;
    countData.Processing = data.filter(
      (e) => e.status !== PENDING && e.status !== COMPLETED
    ).length;
    countData.Paused = data.filter((e) => e.isPaused === true).length;
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/todos/All")
      .then((response) => {
        if (response?.data?.data) {
          setTodos(response.data.data);
        }
      })
      .catch((error) => {
        showToast(error?.response?.data?.error, "error");
        console.error("Error fetching todos:", error);
      });
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/tasks/status")
      .then((response) => {
        if (response?.data) {
          setIsRunning(response?.data?.status);
        }
      })
      .catch((error) => {
        showToast(error?.response?.data?.error, "error");
        console.error("Error fetching status:", error);
      });
  }, []);

  const updateTodo = (updatedTodo) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/todos/${updatedTodo._id}`,
        updatedTodo
      )
      .then(() => {
        const updatedTodos = todos.map((todo) =>
          todo._id === updatedTodo._id ? updatedTodo : todo
        );
        setTodos(updatedTodos);
      })
      .catch((error) => console.error("Error updating todo:", error));
  };

  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/todos/${selectedDelRow._id}`
      )
      .then(() => {
        const updatedTodos = todos.filter(
          (todo) => todo._id !== selectedDelRow._id
        );
        setTodos(updatedTodos);
      })
      .catch((error) => console.error("Error deleting todo:", error))
      .finally(() => handleCloseDeleteConfirmDialog());
  };
  const handlePause = (id, isPaused) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/tasks/${
      isPaused ? "pause" : "continue"
    }/${id}`;
    axios
      .post(url)
      .then((response) => {
        const updatedTodos = todos.map((todo) =>
          todo._id === id ? { ...todo, isPaused } : todo
        );
        setTodos(updatedTodos);
        showToast(response.data.message, "success");
      })
      .catch((error) => {
        showToast(error?.response?.data?.error, "error");
        console.error("Error deleting todo:", error);
      });
  };

  const stopTasks = async () => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/tasks/stop")
      .then(() => setIsRunning(false))
      .catch((error) => {
        showToast(error?.response?.data?.message, "error");
      });
  };
  const startTasks = () => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/tasks/start")
      .then(() => setIsRunning(true))
      .catch((error) => {
        showToast(error?.response?.data?.message, "error");
      });
  };
  const setTodosHandle = (updatedTodoList) => {
    setTodos(updatedTodoList);
  };
  const filterTasks = async (status) => {
    setStatusFilter(status);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/todos/${status}`)
      .then((response) => {
        if (response?.data?.data) setTodos(response.data.data);
      })
      .catch((error) => {
        showToast(error?.response?.data?.error, "error");
        console.error("Error fetching status:", error);
      });
  };

  const handleOpenDeleteConfirmDialog = (row) => {
    setSelectedDelRow(row);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirmDialog = () => {
    setSelectedDelRow(null);
    setOpenDeleteConfirm(false);
  };

  // Function to handle reordering of items
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(todos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTodos(items);

    // Update the backend with the new order
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/todos/reorder`, {
        todos: items,
      });
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };
  return (
    <div className="flex">
      {/* Form Section */}
      <GenerateImageForm todos={todos} setTodos={setTodosHandle} />
      <ConfirmDialog
        isShow={openDeleteConfirm}
        handleClose={handleCloseDeleteConfirmDialog}
        handleDelete={handleDelete}
        selectedRow={selectedDelRow}
      />
      {/* Task List Section */}
      <div className="w-3/4 bg-white p-4 h-screen">
        {/* <h2 className="text-2xl font-bold mb-4 text-center">Generate Image</h2> */}
        <div className="flex justify-between mb-4">
          <div>
            {filterOptions.map((label, index) => (
              <button
                key={index}
                onClick={() => filterTasks(label)}
                className={`px-4 py-2 rounded mr-2 ${
                  statusFilter === label
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-700"
                }`}
              >
                {`${label} (${countData[label]})`}
              </button>
            ))}
          </div>
          <div>
            <button
              onClick={startTasks}
              className={classNames(
                `px-4 py-2 rounded mr-2 ${
                  !isRunning
                    ? "bg-green-500 text-white hover:bg-green-700"
                    : "bg-gray-500 text-gray"
                } `
              )}
              disabled={isRunning}
            >
              Start
            </button>
            <button
              onClick={stopTasks}
              className={classNames(
                `px-4 py-2 rounded ${
                  isRunning
                    ? "bg-red-500 text-white hover:bg-red-700"
                    : "bg-gray-500 text-gray"
                } `
              )}
              // className="bg-red-500 text-white px-4 py-2 rounded"
              disabled={!isRunning}
            >
              Stop
            </button>
          </div>
        </div>
        <div className="overflow-y-auto h-[calc(100%-3rem)]">
          {todos.length === 0 ? (
            <p className="text-center text-gray-500">No tasks available</p>
          ) : (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="todos" isDropDisabled={false}>
                {(provided) => (
                  <div
                    className="space-y-2"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {todos.map((todo, index) => (
                      <Draggable
                        key={todo._id}
                        draggableId={String(todo._id)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TodoItem
                              key={todo._id}
                              todo={todo}
                              updatedTodos={processingTodos}
                              updateTodo={updateTodo}
                              handleOpenDeleteConfirmDialog={
                                handleOpenDeleteConfirmDialog
                              }
                              handlePause={handlePause}
                              isRunning={isRunning}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
    </div>
  );
}

export default GenerateImage;
